export default class Router {
    constructor(includes, includesMap) {
        this.includes = includes;
        this.includesMap = includesMap;

        this.registry = new Map();
        this.singletons = new Map();
        this.transients = new Map();

        this.instanceCount = new Map();
        this.selectorCache = new Map();
    }

    registerTransient(moduleName) {
        if (!this.registry.has(moduleName)) {
            this.registry.set(moduleName, 'transient');
        }

        return this;
    }

    registerSingleton(moduleName) {
        if (!this.registry.has(moduleName)) {
            this.registry.set(moduleName, 'singleton');
        }

        return this;
    }

    get(moduleName) {
        if (!this.registry.has(moduleName)) {
            this.log('Router: module not found in registry');
            return;
        } else {
            var registryItem = this.registry.get(moduleName);

            if (registryItem != 'singleton') {
                this.log('Router: get only accepts singletons this module is registred as a ' + registryItem);
                return;
            } else {
                this.setSingleton(moduleName);
            }
        }
        return this;
    }

    dom(selector, moduleName) {
        if (!this.registry.has(moduleName)) {
            this.log('Router: module not found in registry');
            return;
        }

        if (this.selectorCache.has(moduleName) && this.selectorCache.get(moduleName) === selector) {
            this.log('Router: selector already exists for module' + moduleName + '(' + selector + ')');
            return;
        }

        if ($(selector).length > 0) {
            var registryItem = this.registry.get(moduleName);

            switch (registryItem) {
                case "singleton":
                    this.setSingleton(moduleName);
                    this.selectorCache.set(moduleName, selector);
                    break;
                case "transient":
                    $(selector).each(function (i, elm) {
                        this.setTransient(moduleName, elm);
                        this.selectorCache.set(moduleName, selector);
                    }.bind(this));
                    break;
            }
        }
        return this;
    }

    setSingleton(moduleName) {
        if (!this.singletons.has(moduleName)) {
            var ModuleObject = this.getModuleObjectByName(moduleName);
            var module = new ModuleObject();

            this.singletons.set(moduleName, module);
            this.log('Router: singleton added with name: ' + moduleName);
            return module;
        } else {
            this.log('Router: singleton fetched with name: ' + moduleName);
            return this.singletons.get(moduleName);
        }
    }

    setTransient(moduleName, elm) {
        var currentInstanceCount = this.instanceCount.has(moduleName) ? this.instanceCount.get(moduleName) : 0;
        var incrementedModuleName = moduleName + '_' + currentInstanceCount;

        var ModuleObject = this.getModuleObjectByName(moduleName);
        var module = new ModuleObject(elm);

        this.transients.set(incrementedModuleName, module);
        this.log('Router: transient added with name: ' + incrementedModuleName);
        currentInstanceCount += 1;
        this.instanceCount.set(moduleName, currentInstanceCount);
        return module;
    }

    getInstance(moduleName) {
        return this.setSingleton(moduleName);
    }

    getModuleObjectByName(name) {
        var index = this.includesMap.indexOf(name);

        if (index === -1) {
            return null;
        }

        return this.includes[index];
    }

    log(msg) {
        if (global.debug) {
            console.info('⥱ ' + msg);
        }
    }
}