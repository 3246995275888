/* eslint-disable */
import "../sass/style.scss";

//import { ComponentsBinder } from '@basic-orange/componentsbinder';
//import Library from '@basic-orange/library';
import Router from './router.js';

import verge from 'verge';
import $ from 'jquery';
import 'jquery.scrollto';

import './vendor/foundation-sites/npm.js';
import 'slimmage/slimmage.js';

//only enable when needed:
//import 'picturefill';


import componentIncludes from '../../build/dist/webpack-includes.js';
//var componentsJson = require('../../build/dist/components.json');

import modulesIncludes from '../../build/dist/webpack-includes-modules.js';
import Singleton from "./modules/Singleton";
var modulesJson = require('../../build/dist/modules.json');

window.$ = $;
window.jQuery = $;
var jQuery = $;

jQuery.extend(verge);

$(function () {
    global.$document = $(document);
    global.$window = $(window);
    global.globalData = $('body').data();

    global.debug = (globalData.islocal !== null && globalData.islocal === true);

    //init foundation
    global.$document.foundation();

    //var componentsBinder = new ComponentsBinder(componentIncludes, componentsJson.components);
    //componentsBinder.bindComponentsToDom();

    //load modules via router
    var router = new Router(modulesIncludes, modulesJson.modules);

    router
        .registerTransient("Questionaire")
        .dom(".questionaire", "Questionaire");

    global.resolveReferences = function (data) {
        return Singleton.resolveReferences(data);
    }
});